* {
    box-sizing: border-box;
}

body {
    font-family: 'Big Shoulders Display';
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #000000;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    font-weight: 900;
    line-height: 100%;
}

.Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 120px;
    margin: 120px 0;
    width: 100%;
}

.TopSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 120px;
    margin: 0 120px;
}

.LogoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
}

.Logo {
    max-width: 100%;
}

.LabelPair {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    max-width: 100%;
}

.LabelPairLinkContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 0;
}

.LabelPair .Label,
.LabelPair .Value {
    max-width: 100%;
    text-align: center;
    overflow: hidden;
    overflow-wrap: break-word;
}

.LogoContainer > h1 {
    font-size: 24px;
}

.LabelPair .Label.Small,
.LabelPair .Label.Medium {
    font-size: 16px;
}

.LabelPair .Value.Small {
    font-size: 40px;
}

.LabelPair .Value.Medium {
    font-size: 64px;
}

.LabelPair .Label.Large {
    font-size: 24px;
}

.LabelPair .Value.Large {
    font-size: 128px;
}

.LabelPair a {
    text-decoration: none;
    color: #000000;
    max-width: 100%;
}

.LabelPair a:hover {
    text-decoration: underline;
    cursor: pointer;
}

.BottomSection {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0;
    width: 100%;
}

.BottomRow {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 0;
    flex-grow: 1;
}

.HorizontalLine {
    background-color: #000000;
    height: 1px;
}

.BoxSeparator {
    background-color: #000000;
    width: 1px;
}

.BottomBox {
    padding: 10px;
    background-color: #ffffff;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(50vw - 0.5px);
    height: 336px;
}

.EthereumLogo {
    margin-top: 74px;
}

@media screen and (max-width: 800px) {
    .Container {
        gap: 80px;
        margin: 60px 0;
    }

    .TopSection {
        margin: 0 60px;
    }

    .BottomRow {
        flex-direction: column;
    }

    .BottomBox {
        width: 100%;
    }

    .BoxSeparator {
        height: 1px;
        width: unset;
    }

    .LogoContainer > h1 {
        font-size: 24px;
    }

    .LabelPair .Value.Small {
        font-size: 24px;
    }

    .LabelPair .Value.Medium {
        font-size: 40px;
    }

    .LabelPair .Value.Large {
        font-size: 54px;
    }

    .BottomBox {
        height: 200px;
    }

    .EthereumLogo {
        margin-top: 0px;
    }
}
